// src/components/Footer.js

import React from "react";

const Footer = () => {
  // Styles for the footer component
  const footerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px", // Adjusted padding for a more centered layout
    backgroundColor: "#000", // Black background color
    color: "#fff", // White text color
    fontSize: "16px",
    fontWeight: "bold",
    position: "fixed", // Fixed positioning to remove extra space
    bottom: 0,
    left: 0,
    width: "100%",
    textAlign: "center",
    zIndex: 950,
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.2)",
  };

  const leftSectionStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    paddingLeft: "20px", // Adjusted padding for a more centered layout
  };

  const rightSectionStyle = {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    fontSize: "14px",
    paddingRight: "20px", // Adjusted padding for a more centered layout
  };

  return (
    <footer style={footerStyle}>
      {/* Left Section */}
      <div style={leftSectionStyle}>
        <span>Connect with us on Social Media</span>
      </div>

      {/* Right Section */}
      <div style={rightSectionStyle}>
        <span>
          © {new Date().getFullYear()} PalanatiTechnologies. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;